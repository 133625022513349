const itemStyle = {
  opacity: 0.8,
  normal: {
    label: {
      show: true,
      position: ['50%', '50%'],
      fontSize: 12,
      formatter: (v) => v.data[3],
    },
  },
};

const sizeFunction = function (r, maxRadius) {
  if (!r) {
    return 0;
  }
  return (r / maxRadius) * 80;
};

export const createSeriesOption = (data, settings) => {
  // const settings = {
  //   x: { column: 'x1', name: 'X1' },
  //   y: { column: 'x2', name: 'X2' },
  //   radius: { column: 'y', name: 'Y' },
  //   entity: { column: 'Location', name: 'Location' },
  //   date: { column: 'yearandmonth', name: 'Yearandmonth' },
  // };

  const dMain = data.map((item) => {
    const result = [
      item[settings.x.column],
      item[settings.y.column],
      item[settings.radius.column],
      item[settings.entity.column],
      item[settings.date.column],
    ];
    return result;
  });

  const schema = [
    {
      name: 'X Axis', index: 0, column: '', text: `X ${settings.x.name}`, unit: '',
    },
    {
      name: 'Y Axis', index: 1, text: `Y ${settings.y.name}`, unit: '',
    },
    {
      name: 'Radius', index: 2, text: `Radius ${settings.radius.name}`, unit: '',
    },
    {
      name: 'Location', index: 3, text: 'Location', unit: '',
    },
  ];

  const x = [...new Set(data.map((item) => item[settings.x.column]))].sort();
  const y = [...new Set(data.map((item) => item[settings.y.column]))].sort();
  const radius = [...new Set(data.map((item) => item[settings.radius.column]))].sort();
  const entities = [...new Set(data.map((item) => item[settings.entity.column]))].sort();
  // const uniqueValues = [...new Set(data.map((item) => item[valueColumn]))];
  const dates = [...new Set(data.map((item) => item[settings.date.column]))].sort();

  const minX = Math.min(...x);
  const maxX = Math.max(...x);

  const minY = Math.min(...y);
  const maxY = Math.max(...y);

  const maxRadius = Math.max(...radius);
  // console.log('maxRadius', maxRadius);
  // console.log('x', x);
  // console.log('y', y);
  // console.log('uniqueValues', uniqueValues);
  // console.log('min', min);
  // console.log('max', max);

  // console.log('d', d);

  const d = [];
  dates.forEach((date) => {
    d.push(dMain.filter((item) => item[4] === date));
  });

  // console.log('d', d);

  const option = {
    baseOption: {
      timeline: {
        axisType: 'category',
        orient: 'vertical',
        autoPlay: true,
        inverse: true,
        playInterval: 1000,
        left: null,
        right: 0,
        top: 20,
        bottom: 20,
        width: 55,
        height: null,
        symbol: 'none',
        checkpointStyle: {
          borderWidth: 2,
        },
        controlStyle: {
          showNextBtn: false,
          showPrevBtn: false,
        },
        data: [],
      },
      title: [
        {
          text: dates[0],
          textAlign: 'center',
          left: '63%',
          top: '55%',
          textStyle: {
            fontSize: 100,
          },
        },
        {
          text: 'Text',
          left: 'center',
          top: 10,
          textStyle: {
            fontWeight: 'normal',
            fontSize: 20,
          },
        },
      ],
      tooltip: {
        padding: 5,
        borderWidth: 1,
        formatter(obj) {
          const value = obj.value;
          // prettier-ignore
          return `${schema[3].text}：${value[3]}<br>${
            schema[1].text}：${value[1]}${schema[1].unit}<br>${
            schema[0].text}：${value[0]}${schema[0].unit}<br>${
            schema[2].text}：${value[2]}<br>`;
        },
      },
      grid: {
        top: 100,
        containLabel: true,
        left: 30,
        right: '110',
      },
      xAxis: {
        type: 'log',
        name: 'XAxis',
        max: maxX,
        min: minX,
        nameGap: 25,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: 18,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: '{value} ',
        },
      },
      yAxis: {
        type: 'value',
        name: 'YAxis',
        max: maxY,
        min: minY,
        nameTextStyle: {
          fontSize: 18,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: '{value} ',
        },
      },
      visualMap: [
        {
          show: false,
          dimension: 3,
          categories: entities,
          inRange: {
            color: (function () {
              // prettier-ignore
              const colors = ['#51689b', '#ce5c5c', '#fbc357', '#8fbf8f', '#659d84', '#fb8e6a', '#c77288', '#786090', '#91c4c5', '#6890ba'];
              return colors.concat(colors);
            }()),
          },
        },
      ],
      series: [
        {
          type: 'scatter',
          itemStyle,
          data: d[0],
          symbolSize(val) {
            return sizeFunction(val[2], maxRadius);
          },
        },
      ],
      animationDurationUpdate: 1000,
      animationEasingUpdate: 'quinticInOut',
    },
    options: [],
  };
  for (let n = 0; n < dates.length; n++) {
    option.baseOption.timeline.data.push(dates[n]);
    option.options.push({
      title: {
        show: true,
        text: `${dates[n]}`,
      },
      series: {
        name: dates[n],
        type: 'scatter',
        data: d[n],
        symbolSize(val) {
          return sizeFunction(val[2], maxRadius);
        },
      },
    });
  }

  return option;
};
